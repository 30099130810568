
import { Component, Vue, Watch } from "vue-property-decorator";

// Components
import CRadioButton from "@/components/fields-step/CRadioButton/CRadioButton.vue";
import CInput from "@/components/fields-step/CInput/CInput.vue";
import CRadioButtonList from "@/components/fields-step/CRadioButtonList/CRadioButtonList.vue";
import CSelectSearch from "@/components/fields-step/CSelectSearch/CSelectSearch.vue";
import CInputMoney from "@/components/fields-step/CInputMoney/CInputMoney.vue";
import CBoxConfirmed from "@/components/fields-step/CBoxConfirmed/CBoxConfirmed.vue";

// Interface
import { IBrand, IFormVehicle } from "./interface/IFormVehicle";
import { IFormField } from "./interface/IFormField";
import { IRadioButtonListOption } from "@/components/fields-step/CRadioButtonList/interface/IRadioButtonListOption";
import { IDataConfirmed } from "@/components/fields-step/CBoxConfirmed/interface/IDataConfirmed";

//utils
import {
  validateSelect,
  validateRadio,
  removeStyleError,
} from "@/utils/validations";
import { updatedControllers } from "@/utils/updateControlles";
import useAuth from "@/services/auth/useAuth";
import useAuthStore from "@/store/auth";
import useVehicleStore from "@/store/vehicle";
import { IResponse } from "@/services/interface/auth/IAuth";
import useAppConfigStore from "@/store/appConfig";

@Component({
  name: "VehicleStepGroup",
  components: {
    CRadioButton,
    CRadioButtonList,
    CInput,
    CSelectSearch,
    CInputMoney,
    CBoxConfirmed,
  },
})
export default class VehicleStepGroup extends Vue {
  vehicleStore = useVehicleStore();
  authStore = useAuthStore();
  appConfigStore = useAppConfigStore();

  formVehicle = {
    typeVehicle: {} as IFormField,
    manufactureYear: {} as IFormField,
    modelYear: {} as IFormField,
    brandVehicle: {} as IFormField,
    modelVehicle: {} as IFormField,
    versionVehicle: {} as IFormField,
    priceVehicle: {} as IFormField,
  } as IFormVehicle;

  // id de marca, versão e modelo dos veiculos
  brandVehicleId: number = 0;
  modelVehicleId: number = 0;
  versionVehicleId: number = 0;

  // Variável com a quantidade de opções apresentadas de marca
  numberOptionsBrand: number = 11;

  // variável que armazena id button radio
  idButtonRadioClick = "";

  // Variável que controla ultima requisição
  lastRequest: string = "";

  // Variável que controla apresentação de marcas para uma unica principal marca
  ehOneMainBrand: boolean = false;

  // Rules
  boxConfirmedVehicle: boolean = false;

  optionsTypeVehicle: Array<IRadioButtonListOption> = [
    { id: "typeN", text: "Novo", value: "Novo" },
    { id: "typeU", text: "Usado", value: "Usado" },
  ];

  optionsManufactureYearNewVehicle: Array<IRadioButtonListOption> = [];

  optionsManufactureYear: Array<string> | Array<number> = [];

  optionsBrandVehicle: Array<IRadioButtonListOption> = [];

  optionsBrandVehicleTrue: Array<IRadioButtonListOption> = [];

  optionsModelsVehicle: Array<string> = [];

  optionsVersionVehicle: Array<string> = [];

  /**
   * Reseta os dados
   */
  @Watch("storeResetData", { immediate: true })
  initializeFormReset(): void {
    if (!this.appConfigStore.resetData) {
      return;
    }

    this.formVehicle.typeVehicle.value = "";
    this.formVehicle.typeVehicle.valid = false;
    this.formVehicle.manufactureYear.value = "";
    this.formVehicle.manufactureYear.valid = false;
    this.formVehicle.modelYear.value = "";
    this.formVehicle.modelYear.valid = false;
    this.formVehicle.brandVehicle.value = "";
    this.formVehicle.brandVehicle.valid = false;
    this.formVehicle.modelVehicle.value = "";
    this.formVehicle.modelVehicle.valid = false;
    this.formVehicle.versionVehicle.value = "";
    this.formVehicle.versionVehicle.valid = false;
    this.formVehicle.priceVehicle.value = "";
    this.formVehicle.priceVehicle.valid = false;
  }

  /**
   * Função com algoritmo de ordenação em bolha
   * Irá ordenar o array de marcas requisitado
   * A ordenação é baseada se o fabricabte é melhor marca
   * @param brandform Array[ Object ]
   */
  brandOrder(brandform: Array<IBrand>): void {
    const n = brandform.length;
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        const valorJ = brandform[j].fabricanteEhMelhorMarca ? 0 : 1;
        const valorJ1 = brandform[j + 1].fabricanteEhMelhorMarca ? 0 : 1;
        if (valorJ > valorJ1) {
          const temp = brandform[j];
          brandform[j] = brandform[j + 1];
          brandform[j + 1] = temp;
        }
      }
    }
    this.vehicleStore.$patch(
      (state) => (state.dataFormResponseBrand = brandform)
    );
  }

  /**
   * Função que faz o dos dados do step conforme dados requisitados no banco
   */
  @Watch("storeDataFormResponse", { immediate: true })
  async initializeVehicleStepGrou(): Promise<void> {
    this.appConfigStore.$patch((state) => (state.carregandoServicosVeiculosIniciais = true));
    //Adicionando opções de Marca/Familia
    if (this.authStore.dataFormResponse.fabricantes != null) {
      var optionsBrandVehicleFormResponse =
        this.authStore.dataFormResponse.fabricantes!;
      if (optionsBrandVehicleFormResponse.length <= 0) {
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
        this.appConfigStore.$patch((state) => (state.carregandoServicosVeiculosIniciais = false));
        return;
      }
      const numberOptionsTrue = optionsBrandVehicleFormResponse.filter(
        (item) => item.fabricanteEhMelhorMarca === true
      );

      this.numberOptionsBrand =
        numberOptionsTrue.length >= 11 ? 11 : numberOptionsTrue.length;

      this.ehOneMainBrand = false;

      if (numberOptionsTrue.length == 1) {
        this.formVehicle.brandVehicle.value =
          numberOptionsTrue[0].fabricanteNome;
        this.formVehicle.brandVehicle.valid = true;
        this.formVehicle.brandVehicle.external = true;
        this.ehOneMainBrand = true;
        this.validRadioListBrandVehicle(numberOptionsTrue[0].fabricanteNome);
        updatedControllers();
      }

      this.optionsBrandVehicle = optionsBrandVehicleFormResponse.map(function (
        obj
      ) {
        return {
          id: obj.fabricanteId,
          text: obj.fabricanteNome,
          value: obj.fabricanteNome,
        };
      });

      this.optionsBrandVehicleTrue = numberOptionsTrue.map(function (obj) {
        return {
          id: obj.fabricanteId,
          text: obj.fabricanteNome,
          value: obj.fabricanteNome,
        };
      });
    }

    // Adicionando ano de fabricação
    if (this.authStore.dataFormResponse.anoFabricacao != null) {
      var optionsManufactureYearVehicleFormResponse =
        this.authStore.dataFormResponse.anoFabricacao!;
      this.optionsManufactureYear =
        this.authStore.dataFormResponse.anoFabricacao!;
      this.optionsManufactureYearNewVehicle =
        optionsManufactureYearVehicleFormResponse.map(function (value) {
          return {
            id: value,
            text: value,
            value: value,
          };
        });
    }

    // Adicionando opções de Marca/Familia quando os dados vem da molicar e não o checkuout não retorna os fabricantes
    if (
      this.authStore.dataFormResponse.fabricantes === null &&
      this.authStore.dataFormResponse.veiculo.anoFabricacao != null &&
      this.authStore.dataFormResponse.veiculo.anoModelo != null
    ) {
      const data = {
        pontoVendaId: this.pontoVendaId,
        yearManufacture:
          typeof this.formVehicle.manufactureYear.value === "string"
            ? this.formVehicle.manufactureYear.value
            : JSON.stringify(this.formVehicle.manufactureYear.value),
        yearModel:
          typeof this.formVehicle.modelYear.value === "string"
            ? this.formVehicle.modelYear.value
            : JSON.stringify(this.formVehicle.modelYear.value),
      };

      await this.vehicleStore
        .fetchGetBrand(data)
        .then((response) => {
          this.brandOrder(response.data);
        })
        .catch((error) => {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }

          this.appConfigStore.$patch((state) => (state.requestFailed = true));
        })
        .finally(() =>
          this.appConfigStore.$patch((state) => (state.loading = false))
        );

      let optionsBrandVehicleFormResponse =
        this.vehicleStore.dataFormResponseBrand;
      const numberOptionsTrue = optionsBrandVehicleFormResponse.filter(
        (item) => item.fabricanteEhMelhorMarca === true
      ).length;
      this.numberOptionsBrand =
        numberOptionsTrue >= 11 ? 11 : numberOptionsTrue;
      this.optionsBrandVehicle = optionsBrandVehicleFormResponse.map(function (
        obj
      ) {
        return {
          id: obj.fabricanteId,
          text: obj.fabricanteNome,
          value: obj.fabricanteNome,
        };
      });
    }

    //Adicionando opções vindas do cliente
    if (this.authStore.dataFormResponse.veiculo) {
      if (
        this.authStore.dataFormResponse.veiculo.zeroKm === false ||
        this.authStore.dataFormResponse.veiculo.zeroKm === true
      ) {
        if (this.authStore.dataFormResponse.veiculo.zeroKm) {
          this.formVehicle.typeVehicle.value = "Novo";
        } else {
          this.formVehicle.typeVehicle.value = "Usado";
        }
        this.formVehicle.typeVehicle.external = true;
        this.formVehicle.typeVehicle.valid = true;
      }

      if (this.authStore.dataFormResponse.veiculo.anoFabricacao) {
        this.formVehicle.manufactureYear.value =
          this.authStore.dataFormResponse.veiculo.anoFabricacao!;
        this.formVehicle.manufactureYear.external = true;
        this.formVehicle.manufactureYear.valid = true;
      }

      if (this.authStore.dataFormResponse.veiculo.anoModelo) {
        this.formVehicle.modelYear.value =
          this.authStore.dataFormResponse.veiculo.anoModelo!;
        this.formVehicle.modelYear.external = true;
        this.formVehicle.modelYear.valid = true;
      }

      if (this.authStore.dataFormResponse.veiculo.valorVeiculo) {
        this.formVehicle.priceVehicle.value =
          this.authStore.dataFormResponse.veiculo.valorVeiculo!;
        this.formVehicle.priceVehicle.external = true;
        this.formVehicle.priceVehicle.valid = true;
      }

      if (this.authStore.dataFormResponse.veiculo.fabricante != null) {
        this.formVehicle.brandVehicle.value =
          this.authStore.dataFormResponse.veiculo.fabricante!.fabricanteNome;
        this.brandVehicleId =
          this.authStore.dataFormResponse.veiculo.fabricante!.fabricanteId;
        this.formVehicle.brandVehicle.external = true;
        
        const data = {
          pontoVendaId: this.pontoVendaId,
          yearManufacture:
            typeof this.formVehicle.manufactureYear.value === "string"
              ? this.formVehicle.manufactureYear.value
              : JSON.stringify(this.formVehicle.manufactureYear.value),
          yearModel:
            typeof this.formVehicle.modelYear.value === "string"
              ? this.formVehicle.modelYear.value
              : JSON.stringify(this.formVehicle.modelYear.value),
          manuFactureId: JSON.stringify(this.brandVehicleId),
        };

        await this.vehicleStore
          .fetchGetModel(data)
          .then((response) => {
            this.vehicleStore.$patch(
              (state) => (state.dataFormResponseModel = response.data)
            );
          })
          .catch((error) => {
            if (
              error.response.data.message != null &&
              error.response.data.message != undefined
            ) {
              this.appConfigStore.$patch(
                (state) =>
                  (state.messageRequestFailed = error.response.data.message)
              );
            }

            this.appConfigStore.$patch((state) => (state.requestFailed = true));
          })
          .finally(() =>
            this.appConfigStore.$patch((state) => (state.loading = false))
          );

        this.formVehicle.brandVehicle.valid = true;
        this.optionsModelsVehicle = this.modelVehicle;
      }

      if (this.authStore.dataFormResponse.veiculo.familia != null) {
        this.formVehicle.modelVehicle.value =
          this.authStore.dataFormResponse.veiculo.familia!.familiaNome;
        this.modelVehicleId =
          this.authStore.dataFormResponse.veiculo.familia!.familiaId;

        this.requisitionVersion(
          this.authStore.dataFormResponse.veiculo.familia!.familiaId
        );

        this.formVehicle.modelVehicle.external = true;
        this.formVehicle.modelVehicle.valid = true;
      }

      if (this.authStore.dataFormResponse.veiculo.modelo != null) {
        this.formVehicle.versionVehicle.value =
          this.authStore.dataFormResponse.veiculo.modelo!.modeloNome;
        this.versionVehicleId =
          this.authStore.dataFormResponse.veiculo.modelo!.modeloId;
        this.appConfigStore.$patch(
          (state) => (state.versionId = this.versionVehicleId)
        );
        this.formVehicle.versionVehicle.external = true;
        this.formVehicle.versionVehicle.valid = true;
      }

      if (
        this.authStore.dataFormResponse.config.confirmarDados.includes(
          "ConfirmarDadosVeiculo"
        )
      ) {
        this.boxConfirmedVehicle = true;
      }
    }
    this.vehicleStore.$patch(
      (state) => (state.dataFormVehicle = this.formVehicle)
    );
    this.appConfigStore.$patch((state) => (state.carregandoServicosVeiculosIniciais = false));
  }

  beforeMount(): void {
    this.initializeForm();
  }

  /**
   * Função que faz a requisição da versão
   * @param modelo number
   */
  async requisitionVersion(modelo: number): Promise<void> {
    this.lastRequest = "version";
    this.validRequests();

    const data = {
      pontoVendaId: this.pontoVendaId,
      yearManufacture:
        typeof this.formVehicle.manufactureYear.value === "string"
          ? this.formVehicle.manufactureYear.value
          : JSON.stringify(this.formVehicle.manufactureYear.value),
      yearModel:
        typeof this.formVehicle.modelYear.value === "string"
          ? this.formVehicle.modelYear.value
          : JSON.stringify(this.formVehicle.modelYear.value),
      familyId: JSON.stringify(modelo),
    };

    await this.vehicleStore
      .fetchGetVersion(data)
      .then((response) => {
        this.vehicleStore.$patch(
          (state) => (state.dataFormResponseVersion = response.data)
        );
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );

    Promise.resolve().then(() => {
      this.optionsVersionVehicle = this.versionVehicle;
    });
  }

  @Watch("optionsBrandVehicleWhatch")
  async removeSelectBrand(): Promise<void> {
    if (!this.formVehicle.brandVehicle.external) {
      this.formVehicle.brandVehicle.value = "";
      this.formVehicle.brandVehicle.valid = false;

      Promise.resolve().then(() => {
        validateRadio("brandVehicle");
      });

      validateSelect("brandVehicle");
    }
  }

  /**
   * função para atualizar o valor da marca conforme dados da simulação
   */
  @Watch("formBrand")
  updateBrand(): void {
    this.appConfigStore.$patch((state) => (state.loading = false));
    var optionsBrandVehicleFormResponse =
      this.vehicleStore.dataFormResponseBrand;
    if (optionsBrandVehicleFormResponse.length <= 0) {
      this.appConfigStore.$patch((state) => (state.requestFailed = true));
      return;
    }
    const numberOptionsTrue = optionsBrandVehicleFormResponse.filter(
      (item) => item.fabricanteEhMelhorMarca === true
    );
    this.numberOptionsBrand =
      numberOptionsTrue.length >= 11 ? 11 : numberOptionsTrue.length;

    this.ehOneMainBrand = false;

    if (numberOptionsTrue.length == 1) {
      this.formVehicle.brandVehicle.value = numberOptionsTrue[0].fabricanteNome;
      this.formVehicle.brandVehicle.valid = true;
      this.formVehicle.brandVehicle.external = true;
      this.ehOneMainBrand = true;
      this.validRadioListBrandVehicle(numberOptionsTrue[0].fabricanteNome);
      updatedControllers();
    }

    this.optionsBrandVehicle = optionsBrandVehicleFormResponse.map(function (
      obj
    ) {
      return {
        id: obj.fabricanteId,
        text: obj.fabricanteNome,
        value: obj.fabricanteNome,
      };
    });
    this.optionsBrandVehicleTrue = numberOptionsTrue.map(function (obj) {
      return {
        id: obj.fabricanteId,
        text: obj.fabricanteNome,
        value: obj.fabricanteNome,
      };
    });
  }

  /**
   * Função para inicialiar formulário do grupo Vehicle
   */
  initializeForm(): void {
    const defaultInitialize: IFormField = {
      value: "",
      external: false,
      valid: false,
      messageError: "⚠ Campo obrigatório!",
    };

    this.formVehicle.typeVehicle = Object.assign({}, defaultInitialize);
    this.formVehicle.licensePlate = Object.assign({}, defaultInitialize);
    this.formVehicle.manufactureYear = Object.assign({}, defaultInitialize);
    this.formVehicle.modelYear = Object.assign({}, defaultInitialize);
    this.formVehicle.brandVehicle = Object.assign({}, defaultInitialize);
    this.formVehicle.modelVehicle = Object.assign({}, defaultInitialize);
    this.formVehicle.versionVehicle = Object.assign({}, defaultInitialize);
    this.formVehicle.priceVehicle = Object.assign({}, defaultInitialize);
  }

  // Utils component
  removeItemsDataConfirmed(
    array: Array<any>,
    itemToRemove: Array<string>
  ): Array<any> {
    return array.filter((v) => {
      return !itemToRemove.includes(v.titleData);
    });
  }

  /**
   * Função que valida o tipo de veículo
   * @param event string
   */
  validRadioTypeVehicle(event: string): void {
    this.formVehicle.typeVehicle.valid = true;

    if (
      this.formVehicle.typeVehicle.value &&
      this.formVehicle.typeVehicle.value != event
    ) {
      this.removeSelectManufactureYear();
      this.removeSelectModelYear();
      this.removeSelectModelVehicle();
      this.removeSelectVersionVehicle();
    }

    this.formVehicle.typeVehicle.value = event;

    //removendo notificação de campo com erro
    removeStyleError();

    this.idButtonRadioClick = "type" + event.charAt(0);
    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
  }

  /**
   * Função para validar o ano de fabricação
   * @param event string
   */
  async validRadioListManufactureYear(event: string): Promise<void> {
    this.lastRequest = "manufacture";
    this.formVehicle.manufactureYear.valid = false;

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    if (
      this.formVehicle.manufactureYear.value &&
      this.formVehicle.manufactureYear.value != event
    ) {
      this.removeSelectModelYear();
      this.removeSelectModelVehicle();
      this.removeSelectVersionVehicle();
    }

    this.formVehicle.manufactureYear.value = event;

    if (
      this.formVehicle.manufactureYear.value != "" &&
      this.formVehicle.manufactureYear.value != null
    ) {
      if (
        this.formVehicle.modelYear.value &&
        this.formVehicle.modelYear.value != ""
      ) {
        this.appConfigStore.$patch((state) => (state.loading = true));
        this.validRequests();

        this.formVehicle.brandVehicle.valid = false;
        this.formVehicle.brandVehicle.value = "";

        const data = {
          pontoVendaId: this.pontoVendaId,
          yearManufacture:
            typeof this.formVehicle.manufactureYear.value === "string"
              ? this.formVehicle.manufactureYear.value
              : JSON.stringify(this.formVehicle.manufactureYear.value),
          yearModel:
            typeof this.formVehicle.modelYear.value === "string"
              ? this.formVehicle.modelYear.value
              : JSON.stringify(this.formVehicle.modelYear.value),
        };

        await this.vehicleStore
          .fetchGetBrand(data)
          .then((response) => {
            this.brandOrder(response.data);
          })
          .catch((error) => {
            if (
              error.response.data.message != null &&
              error.response.data.message != undefined
            ) {
              this.appConfigStore.$patch(
                (state) =>
                  (state.messageRequestFailed = error.response.data.message)
              );
            }

            this.appConfigStore.$patch((state) => (state.requestFailed = true));
          })
          .finally(() =>
            this.appConfigStore.$patch((state) => (state.loading = false))
          );
      }

      if (!this.appConfigStore.requestFailed) {
        this.formVehicle.manufactureYear.valid = true;

        //Indo para o próximo step caso seja o componente radio
        var elementRadio = document.querySelector(
          "input[type=radio][name=factureYear]:checked"
        );
        if (elementRadio) {
          this.idButtonRadioClick = event;
          this.appConfigStore.$patch(
            (state) =>
              (state.activeActionButton =
                !this.appConfigStore.activeActionButton)
          );
        }
      }
    } else {
      this.formVehicle.manufactureYear.valid = false;
    }
  }

  /**
   * Função para remover opção selecionada, caso aconteça um erro na requisição
   */
  @Watch("validRequest")
  validRequests(): void {
    if (this.appConfigStore.requestFailed) {
      if (this.lastRequest == "version") {
        this.removeSelectVersionVehicle();
      } else if (this.lastRequest == "manufacture") {
        this.removeSelectManufactureYear();
      } else if (this.lastRequest == "modelYear") {
        this.lastRequest = "";
        this.removeSelectModelYear();
      } else if (this.lastRequest == "brand") {
        this.removeSelectBrand();
      } else if (this.lastRequest == "model") {
        this.removeSelectModelVehicle();
      }
    }
  }

  /**
   * Função utilizada para validar ano modelo
   * @param event string
   */
  async validRadioModelYear(event: string): Promise<void> {
    this.lastRequest = "modelYear";

    //removendo notificação de campo com erro
    removeStyleError();

    if (
      this.formVehicle.modelYear.value &&
      this.formVehicle.modelYear.value != event
    ) {
      this.removeSelectModelVehicle();
      this.removeSelectVersionVehicle();
    }

    this.formVehicle.modelYear.value = event;

    const data = {
      pontoVendaId: this.pontoVendaId,
      yearManufacture:
        typeof this.formVehicle.manufactureYear.value === "string"
          ? this.formVehicle.manufactureYear.value
          : JSON.stringify(this.formVehicle.manufactureYear.value),
      yearModel:
        typeof this.formVehicle.modelYear.value === "string"
          ? this.formVehicle.modelYear.value
          : JSON.stringify(this.formVehicle.modelYear.value),
    };

    this.appConfigStore.$patch((state) => (state.loading = true));
    this.validRequests();

    this.formVehicle.brandVehicle.valid = false;
    this.formVehicle.brandVehicle.value = "";

    await this.vehicleStore
      .fetchGetBrand(data)
      .then((response) => {
        if (response.data.length <= 0) {
          this.appConfigStore.$patch((state) => (state.requestFailed = true));
          this.formVehicle.modelYear.value = -1;
          this.formVehicle.modelYear.valid = false;
          return;
        }
        this.brandOrder(response.data);
        this.formVehicle.modelYear.valid = true;
      })
      .catch((error) => {
        if (
          error.response.data.message != null &&
          error.response.data.message != undefined
        ) {
          this.appConfigStore.$patch(
            (state) =>
              (state.messageRequestFailed = error.response.data.message)
          );
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
        this.formVehicle.modelYear.value = -1;
        this.formVehicle.modelYear.valid = false;
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );

    this.idButtonRadioClick = "model-" + event;
    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
  }

  /**
   * Função utilizada para validar marca do veículo
   * @param event string
   */
  async validRadioListBrandVehicle(event: string): Promise<void> {
    this.lastRequest = "brand";

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    if (
      this.formVehicle.brandVehicle.value &&
      this.formVehicle.brandVehicle.value != event
    ) {
      this.removeSelectModelVehicle();
      this.removeSelectVersionVehicle();
    }

    this.formVehicle.brandVehicle.value = event;

    if (
      this.formVehicle.brandVehicle.value != "" &&
      this.formVehicle.brandVehicle.value != null
    ) {
      //Armazenando Id da marca/fabicação
      // para futuramente realizar requisição do modelo/Familia
      const positionBrandVehicleInArray = this.brandVehicle.indexOf(event);
      this.brandVehicleId = Number(
        this.optionsBrandVehicleId[positionBrandVehicleInArray]
      );

      this.appConfigStore.$patch((state) => (state.loading = true));
      this.validRequests();

      const data = {
        pontoVendaId: this.pontoVendaId,
        yearManufacture:
          typeof this.formVehicle.manufactureYear.value === "string"
            ? this.formVehicle.manufactureYear.value
            : JSON.stringify(this.formVehicle.manufactureYear.value),
        yearModel:
          typeof this.formVehicle.modelYear.value === "string"
            ? this.formVehicle.modelYear.value
            : JSON.stringify(this.formVehicle.modelYear.value),
        manuFactureId: JSON.stringify(this.brandVehicleId),
      };

      await this.vehicleStore
        .fetchGetModel(data)
        .then((response) => {
          this.vehicleStore.$patch(
            (state) => (state.dataFormResponseModel = response.data)
          );
        })
        .catch((error) => {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }
          this.appConfigStore.$patch((state) => (state.requestFailed = true));
        })
        .finally(() =>
          this.appConfigStore.$patch((state) => (state.loading = false))
        );

      Promise.resolve().then(() => {
        this.formVehicle.brandVehicle.valid = true;
        this.appConfigStore.$patch((state) => (state.loading = false));

        Promise.resolve().then(() => {
          this.optionsModelsVehicle = this.modelVehicle;
          this.appConfigStore.$patch((state) => (state.loading = false));
          var elementRadio = document.querySelector(
            "input[type=radio][name=brandVehicle]:checked"
          );
          if (elementRadio) {
            this.idButtonRadioClick = elementRadio.id;
            this.appConfigStore.$patch(
              (state) =>
                (state.activeActionButton =
                  !this.appConfigStore.activeActionButton)
            );
          }
        });
      });
    } else {
      this.formVehicle.brandVehicle.valid = false;
    }
  }

  /**
   * Função utilizada para validar modelo do veículo
   * @param event string
   */
  async validSelectSearchModelVehicle(event: string): Promise<void> {
    this.lastRequest = "model";

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    if (
      this.formVehicle.modelVehicle.value &&
      this.formVehicle.modelVehicle.value != event
    ) {
      this.removeSelectVersionVehicle();
    }
    this.formVehicle.modelVehicle.value = event;

    if (
      this.formVehicle.modelVehicle.value != "" &&
      this.formVehicle.modelVehicle.value != null
    ) {
      const positionModelVehicle = this.modelVehicle.indexOf(event);
      this.modelVehicleId = this.optionsModelVehicleId[positionModelVehicle];
      this.formVehicle.modelVehicle.valid = true;
      await this.requisitionVersion(
        this.optionsModelVehicleId[positionModelVehicle]
      );
    } else {
      this.formVehicle.modelVehicle.valid = false;
    }
  }

  validSelectSearchVersionVehicle(event: string): void {
    this.formVehicle.versionVehicle.value = event;

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    if (
      this.formVehicle.versionVehicle.value != "" &&
      this.formVehicle.versionVehicle.value != null
    ) {
      const positionVersionVehicle = this.versionVehicle.indexOf(event);
      this.versionVehicleId =
        this.optionsVersionVehicleId[positionVersionVehicle];
      this.appConfigStore.$patch(
        (state) => (state.versionId = this.versionVehicleId)
      );
      this.formVehicle.versionVehicle.valid = true;
    } else {
      this.formVehicle.versionVehicle.valid = false;
    }
  }

  validInputPriceVehicle(event: string): void {
    this.formVehicle.priceVehicle.value = event;
    this.formVehicle.priceVehicle.valid = true;

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    const formatPrice = event
      .toString()
      .replaceAll(".", "")
      .replaceAll(",", ".");

    const isValidPriceVehicleEntryValue = this.validatePriceVehicleEntryValue(
      event,
      this.appConfigStore.dataFormFinancing.entryValue
    );

    if (!isValidPriceVehicleEntryValue) {
      this.formVehicle.priceVehicle.valid = false;
      this.formVehicle.priceVehicle.messageError =
        "⚠ O valor informado não pode ser inferior ao valor de entrada. Por favor, Informe um valor diferente!";
    }

    if (
      !this.formVehicle.priceVehicle.value ||
      this.formVehicle.priceVehicle.value == "0,00" ||
      parseFloat(formatPrice) < 0
    ) {
      this.formVehicle.priceVehicle.valid = false;
      this.formVehicle.priceVehicle.messageError =
        "⚠ Informe o valor para prosseguir!";
    }

    if (parseFloat(formatPrice) > 9999999.99) {
      this.formVehicle.priceVehicle.valid = false;
      this.formVehicle.priceVehicle.messageError =
        "⚠ O valor informado não pode ser superior a R$ 9.999.999,99!";
    }
  }

  /**
   * Função utilizada para validar se o preço do veículo é maior que a parcela enviada
   * @param value string | number
   * @returns boolean
   */
  validatePriceVehicleEntryValue(value: string, parcel: IFormField): boolean {
    //removendo notificação de campo com erro
    removeStyleError();

    if (!parcel) {
      return true;
    }

    if (parcel.external && typeof parcel.value == "number") {
      const formatPrice = value
        .toString()
        .replaceAll(".", "")
        .replaceAll(",", ".");

      return parseFloat(formatPrice) > parcel.value;
    }

    return true;
  }

  /*
   * Função para remover a seleção da versão escolhida
   */
  removeSelectVersionVehicle(): void {
    if (
      !this.formVehicle.versionVehicle.external ||
      this.appConfigStore.resetData ||
      this.appConfigStore.editionSteps
    ) {
      this.formVehicle.versionVehicle.value = "";
      this.formVehicle.versionVehicle.valid = false;
      validateSelect("versionVehicle");
    }
  }

  /**
   * Função para remover a seleção do modelo escolhido
   */
  removeSelectModelVehicle(): void {
    if (
      !this.formVehicle.modelVehicle.external ||
      this.appConfigStore.resetData ||
      this.appConfigStore.editionSteps
    ) {
      this.formVehicle.modelVehicle.value = "";
      this.formVehicle.modelVehicle.valid = false;
      validateSelect("modelVehicle");
    }
  }

  /**
   * Função para remover a seleção do ano de Fabricação escolhido
   */
  removeSelectManufactureYear(): void {
    if (
      !this.formVehicle.manufactureYear.external ||
      this.appConfigStore.resetData ||
      this.appConfigStore.editionSteps
    ) {
      this.formVehicle.manufactureYear.value = "";
      this.formVehicle.manufactureYear.valid = false;
      validateRadio("factureYear");
      validateSelect("factureYear");
    }
  }

  /**
   * Função para remover a seleção do ano do modelo escolhido
   */
  removeSelectModelYear(): void {
    if (
      !this.formVehicle.modelYear.external ||
      this.appConfigStore.resetData ||
      this.appConfigStore.editionSteps
    ) {
      this.formVehicle.modelYear.value = "";
      this.formVehicle.modelYear.valid = false;
      validateRadio("modelYear");
    }
  }

  /**
   * Ajuda na chamada da função updateBrand()
   */
  get formBrand(): Array<IBrand> {
    return this.vehicleStore.dataFormResponseBrand;
  }

  /**
   * Variável auxiliar para retornar falha ou sucesso na requisição
   */
  get validRequest(): boolean {
    return this.appConfigStore.requestFailed;
  }

  /**
   * Variável auxiliar para retornar resposta de requisição
   */
  get storeDataFormResponse(): IResponse {
    return this.authStore.dataFormResponse;
  }

  get modelOption(): Array<IRadioButtonListOption> {
    let valueManufactureYear = 0;
    if (this.formVehicle.manufactureYear.value) {
      valueManufactureYear = parseInt(
        this.formVehicle.manufactureYear.value.toString()
      );
    }

    var optionsModel: Array<IRadioButtonListOption> = [
      {
        id: "model-" + (valueManufactureYear + 1),
        text: (valueManufactureYear + 1).toString(),
        value: valueManufactureYear + 1,
      },
      {
        id: "model-" + valueManufactureYear,
        text: valueManufactureYear.toString(),
        value: valueManufactureYear,
      },
    ];

    return optionsModel;
  }

  /*
   * Gerando array com o nome das opções de marca
   */
  get brandVehicle(): Array<string> {
    const dataFormResponseModel =
      this.vehicleStore.dataFormResponseBrand.length <= 0
        ? this.authStore.dataFormResponse.fabricantes
        : this.vehicleStore.dataFormResponseBrand;
    const optionsBrandVehicle = dataFormResponseModel!.map(function (obj) {
      return obj.fabricanteNome;
    });
    return optionsBrandVehicle;
  }

  /*
   * Gerando array com o nome das opções de marca
   */
  get optionsBrandVehicleId(): Array<number> {
    const dataFormResponseModel =
      this.vehicleStore.dataFormResponseBrand.length <= 0
        ? this.authStore.dataFormResponse.fabricantes
        : this.vehicleStore.dataFormResponseBrand;
    const optionsBrandVehicle = dataFormResponseModel!.map(function (obj) {
      return obj.fabricanteId;
    });
    return optionsBrandVehicle;
  }

  /*
   * Gerando array com o nome das opções de modelos
   */
  get modelVehicle(): Array<string> {
    const dataFormResponseModel = this.vehicleStore.dataFormResponseModel;
    const optionsModelVehicle = dataFormResponseModel.map(function (obj) {
      return obj.familiaNome;
    });
    return optionsModelVehicle;
  }

  /*
   * Gerando array com o id das opções de modelos
   */
  get optionsModelVehicleId(): Array<number> {
    const dataFormResponseModel = this.vehicleStore.dataFormResponseModel;
    const optionsModelVehicleId = dataFormResponseModel.map(function (obj) {
      return obj.familiaId;
    });
    return optionsModelVehicleId;
  }

  /*
   * Gerando array com o nome das opções de versão
   */
  get versionVehicle(): Array<string> {
    const dataFormResponseVersion = this.vehicleStore.dataFormResponseVersion;
    const optionsVersionVehicle = dataFormResponseVersion.map(function (obj) {
      return obj.modeloNome;
    });
    return optionsVersionVehicle;
  }

  /*
   * Gerando array com o id das opções de versão
   */
  get optionsVersionVehicleId(): Array<number> {
    const dataFormResponseVersion = this.vehicleStore.dataFormResponseVersion;
    const optionsVersionVehicleId = dataFormResponseVersion.map(function (obj) {
      return obj.modeloId;
    });
    return optionsVersionVehicleId;
  }

  get dataConfirmed(): Array<IDataConfirmed> {
    var _dataConfirmed = [] as Array<IDataConfirmed>;

    _dataConfirmed = [
      {
        titleData: "Tipo do veículo",
        data: this.formVehicle.typeVehicle.value,
      },
      {
        titleData: "Ano de fabricação",
        data: this.formVehicle.manufactureYear.value,
      },
      { titleData: "Ano do modelo", data: this.formVehicle.modelYear.value },
      { titleData: "Marca", data: this.formVehicle.brandVehicle.value },
      { titleData: "Modelo", data: this.formVehicle.modelVehicle.value },
      { titleData: "Versão", data: this.formVehicle.versionVehicle.value },
    ];

    //Adicionando valor do veiculo apenas se permitido
    let item = {
      titleData: "Valor",
      data: `R$ ${this.formVehicle.priceVehicle.value.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`,
    };
    if (this.authStore.dataFormResponse.config.exibeValorVeiculo) {
      _dataConfirmed.push(item);
    }

    return _dataConfirmed;
  }

  /**
   * Retorna store boleana que controla o reset dos dados
   */
  get storeResetData(): boolean {
    return this.appConfigStore.resetData;
  }

  /**
   * Retorna opções de marca do veiculo
   */
  get optionsBrandVehicleWhatch(): Array<IRadioButtonListOption> {
    return this.optionsBrandVehicle;
  }

  /**
   * Retorna se o step será ou não apresentado
   */
  get showStep(): boolean {
    return (
      this.appConfigStore.allActiveSteps || this.appConfigStore.editionSteps
    );
  }

  /**
   * Retorna o ponto de venda
   */
  get pontoVendaId(): string {
    return useAuth.getPontoVendaId() || "";
  }
}
