
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

// Components
import CInputRangeNumber from "@/components/fields-step/CInputRangeNumber/InputRangeNumber.vue";
import CRadioButton from "@/components/fields-step/CRadioButton/CRadioButton.vue";
import CRadioButtonList from "@/components/fields-step/CRadioButtonList/CRadioButtonList.vue";
import CBoxConfirmed from "@/components/fields-step/CBoxConfirmed/CBoxConfirmed.vue";

// Interface
import { IDataConfirmed } from "@/components/fields-step/CBoxConfirmed/interface/IDataConfirmed";
import { IFormField } from "./interface/IFormField";
import { IFormFinancing } from "./interface/IFormFinancing";
import { IRadioButtonListOption } from "@/components/fields-step/CRadioButtonList/interface/IRadioButtonListOption";

import { removeStyleError } from "@/utils/validations";

//Store
import useAuthStore from "@/store/auth";
import useVehicleStore from "@/store/vehicle";
import useAppConfigStore from "@/store/appConfig";

@Component({
  name: "FinancingStepGroup",
  components: {
    CInputRangeNumber,
    CRadioButton,
    CRadioButtonList,
    CBoxConfirmed,
  },
})
export default class FinancingStepGroup extends Vue {
  @Prop({ default: "#2F2F2F" }) colorMain!: string;

  vehicleStore = useVehicleStore();
  authStore = useAuthStore();
  appConfigStore = useAppConfigStore();

  formFinancing = {
    entryValue: {} as IFormField,
    percentEntryValue: {} as IFormField,
    parcel: {} as IFormField,
  } as IFormFinancing;

  optionsParcel: Array<IRadioButtonListOption> = [];

  // variável que armazena id button radio
  idButtonRadioClick = "";

  // Rules
  boxConfirmedFinancing: boolean = false;

  /**
   * Reseta os dados
   */
  @Watch("storeResetData", { immediate: true })
  initializeFormReset(): void {
    if (!this.appConfigStore.resetData) {
      return;
    }

    this.formFinancing.entryValue.value = "0.00";
    this.formFinancing.percentEntryValue.value = 0;
    this.formFinancing.entryValue.valid = true;
    this.formFinancing.percentEntryValue.valid = true;
    this.formFinancing.parcel.value = "";
    this.formFinancing.parcel.valid = false;
  }

  /**
   * Função que faz o dos dados do step conforme dados requisitados no banco
   */
  @Watch("storeDataFormResponse", { immediate: true })
  initializeFinancingStepGrou(): void {
    if (this.authStore.dataFormResponse.parcelas != null) {
      var optionsParcelFinancingFormResponse =
        this.authStore.dataFormResponse.parcelas!;
      this.optionsParcel = optionsParcelFinancingFormResponse.map(function (
        value
      ) {
        return {
          id: value,
          text: JSON.stringify(value),
          value: value,
        };
      });
    }

    if (this.authStore.dataFormResponse.simulacao) {
      //Validando se existe valor de entrada e valor do veículo é maior que a parcela
      if (
        this.authStore.dataFormResponse.simulacao.valorEntrada &&
        this.authStore.dataFormResponse.veiculo.valorVeiculo &&
        this.authStore.dataFormResponse.simulacao.valorEntrada! <
          this.authStore.dataFormResponse.veiculo.valorVeiculo!
      ) {
        this.formFinancing.entryValue.value =
          this.authStore.dataFormResponse.simulacao.valorEntrada!;
        this.formFinancing.percentEntryValue.value =
          this.percentEntryValueExternal;
        this.formFinancing.entryValue.valid = true;
        this.formFinancing.percentEntryValue.valid = true;
        this.formFinancing.entryValue.external = true;

        this.formFinancing.percentEntryValue.external = true;
      } else {
        //Permitir passar com valor igual a 0.00
        this.formFinancing.entryValue.value = "0.00";
        this.formFinancing.percentEntryValue.value = 0;
        this.formFinancing.entryValue.valid = true;
        this.formFinancing.percentEntryValue.valid = true;
      }

      if (this.authStore.dataFormResponse.simulacao.quantidadeParcelas) {
        if (this.amountValidParcel()) {
          this.formFinancing.parcel.value =
            this.authStore.dataFormResponse.simulacao.quantidadeParcelas!;
          this.formFinancing.parcel.external = true;
          this.formFinancing.parcel.valid = true;
        }
      }
      if (
        this.authStore.dataFormResponse.config.confirmarDados.includes(
          "ConfirmarDadosFinanciamento"
        )
      ) {
        this.boxConfirmedFinancing = true;
      }
    }
    this.appConfigStore.$patch(
      (state) => (state.dataFormFinancing = this.formFinancing)
    );
  }

  /**
   * Método padrão do vue para ao iniciar form
   */
  beforeMount(): void {
    this.initializeForm();
  }

  /**
   * Validando se a quantidade de parcelas está entre as opções configuradas
   */
  amountValidParcel(): boolean {
    if (
      this.authStore.dataFormResponse.parcelas!.indexOf(
        this.authStore.dataFormResponse.simulacao.quantidadeParcelas!
      ) != -1
    ) {
      return true;
    }
    return false;
  }

  /*
   * Função para inicialiar formulário do grupo Financing
   */
  initializeForm(): void {
    const defaultInitialize: IFormField = {
      value: "",
      external: false,
      valid: false,
      messageError: "⚠ Campo obrigatório!",
    };

    defaultInitialize.messageError = "⚠ Informe o valor para prosseguir!";
    this.formFinancing.entryValue = Object.assign({}, defaultInitialize);
    defaultInitialize.messageError = "⚠ Informe o valor para prosseguir!";
    this.formFinancing.percentEntryValue = Object.assign({}, defaultInitialize);
    defaultInitialize.messageError =
      "⚠ Selecione ao menos uma opção para prosseguir!";
    this.formFinancing.parcel = Object.assign({}, defaultInitialize);
  }

  /**
   * Função para validar valores da entrada
   * @param event object
   */
  validInputRangeNumber(event: {
    real: number | string;
    percent: number | string;
  }): void {
    this.formFinancing.entryValue.value = event.real;
    this.formFinancing.percentEntryValue.value = event.percent;
    this.formFinancing.entryValue.valid = false;
    this.formFinancing.percentEntryValue.valid = false;
    this.formFinancing.entryValue.messageError =
      "⚠ Informe o valor para prosseguir!";

    //removendo notificação de campo com erro
    removeStyleError();

    if (
      typeof this.formFinancing.percentEntryValue.value == "number" &&
      this.formFinancing.percentEntryValue.value > 99
    ) {
      this.formFinancing.entryValue.messageError =
        "⚠ O valor da entrada precisa ser menor que 99%!";
      return;
    }

    this.formFinancing.entryValue.valid = true;
    this.formFinancing.percentEntryValue.valid = true;
  }

  validRadioParcel(event: string): void {
    this.formFinancing.parcel.value = event;
    this.formFinancing.parcel.valid = true;
    this.idButtonRadioClick = event;

    //removendo notificação de campo com erro
    removeStyleError();

    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
  }

  /**
   * Função para formatar o percentual do veiculo
   * para casos do valor do veiculo vir externo e o percentual interno
   */
  @Watch("valuePercentyVehicle")
  valuePercentPriceVehicle(): void {
    if (this.authStore.dataFormResponse.simulacao) {
      if (this.authStore.dataFormResponse.simulacao.valorEntrada) {
        if (this.vehicleStore.dataFormVehicle.priceVehicle) {
          const formatPrice =
            this.vehicleStore.dataFormVehicle.priceVehicle.value
              .toString()
              .replaceAll(".", "")
              .replaceAll(",", ".");
          const percent = (
            (100 * this.authStore.dataFormResponse.simulacao.valorEntrada!) /
            parseFloat(formatPrice)
          ).toFixed(2);
          this.formFinancing.percentEntryValue.value = percent;
        }
      }
    }
  }

  get valuePercentyVehicle(): any {
    return this.appConfigStore.dataFormFinancing.percentEntryValue;
  }

  //Cauculo de percentual para valores externos
  get percentEntryValueExternal(): string {
    const valueVehicle: number| null = this.authStore.dataFormResponse.veiculo.valorVeiculo;
    const valueEntry: number =
      typeof this.authStore.dataFormResponse.simulacao.valorEntrada == "number"
        ? this.authStore.dataFormResponse.simulacao.valorEntrada
        : 0;
    if (!valueVehicle || valueVehicle === 0 || valueEntry === 0) {
      return "0";
    }
    const percent: string = ((100 * valueEntry) / valueVehicle).toFixed(2);
    return percent;
  }

  /**
   * Variável auxiliar para retornar resposta de requisição
   */
  get storeDataFormResponse(): any {
    return this.authStore.dataFormResponse;
  }

  /**
   * Retorno do valor do veiculo para criação do data;
   */
  get valueVehicle(): any {
    if (this.vehicleStore.dataFormVehicle.priceVehicle) {
      return this.vehicleStore.dataFormVehicle.priceVehicle.value;
    }
    return 0.0;
  }

  get entryValueFormatted(): string {
    if (typeof this.formFinancing.entryValue.value == "string") {
      const formatPrice = parseFloat(this.formFinancing.entryValue.value);
      return formatPrice.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    }
    return this.formFinancing.entryValue.value.toLocaleString("pt-br", {
      minimumFractionDigits: 2,
    });
  }

  get dataConfirmed(): Array<IDataConfirmed> {
    var _dataConfirmed = [] as Array<IDataConfirmed>;

    _dataConfirmed = [
      {
        titleData: "Valor de entrada",
        data: `R$ ${this.entryValueFormatted} (${this.formFinancing.percentEntryValue.value}%)`,
      },
      { titleData: "Parcelas", data: this.formFinancing.parcel.value },
    ];
    let item = {
      titleData: "Valor do veículo",
      data: `R$ ${this.valueVehicle.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`,
    };
    if (this.authStore.dataFormResponse.config.exibeValorVeiculo) {
      _dataConfirmed.unshift(item);
    }
    return _dataConfirmed;
  }
  get carPrice(): number {
    if (this.appConfigStore.isFinancingStep) {
      const formatPrice = this.vehicleStore.dataFormVehicle.priceVehicle.value
        .toString()
        .replaceAll(".", "")
        .replaceAll(",", ".");

      return parseFloat(formatPrice);
    }
    return 0;
  }

  /**
   * Retorna store boleana que controla o reset dos dados
   */
  get storeResetData(): any {
    return this.appConfigStore.resetData;
  }

  /**
   * Retorna se o step será ou não apresentado
   */
  get showStep(): boolean {
    return (
      this.appConfigStore.allActiveSteps || this.appConfigStore.editionSteps
    );
  }
}
