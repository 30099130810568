
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import { VMoney } from "v-money";
import "vue-slider-component/theme/default.css";

@Component({
  name: "InputRangeNumber",
  components: {
    VueSlider,
  },
  directives: { money: VMoney },
})
export default class InputRangeNumber extends Vue {
  @Prop() defaultColor!: string;
  @Prop() valueCar!: number;
  @Prop() updateValueCar!: any;
  @Prop() linkFipe!: string;
  @Prop({ default: "⚠ Campo obrigatório!" }) dataErrorMenssage!: string;
  @Prop({ default: "id" }) id!: string;
  @Prop({ default: 0 }) valuePercenty!: any;
  @Prop({ default: 0 }) valueEntry!: any;
  @Prop({ default: false }) valueExternal!: boolean;
  @Prop({ default: "#2F2F2F" }) colorMain!: string;

  value: number = this.valuePercenty;
  formatter: string = "{value}%";
  valueReal: number | string = this.valueEntry.toLocaleString("pt-br", {
    minimumFractionDigits: 2,
  });
  maxValueCar = (this.valueCar * 0.99).toFixed(2);

  //Variável auxiliar para controle de formatação do valor
  valueInput: boolean = false;

  /**
   * Função utilizada para limpar campos
   * caso valor do veículo seja alterado
   */
  @Watch("updateValueCar", { immediate: true })
  removeValues(): void {
    if (!this.valueExternal) {
      this.valueReal = 0;
      this.value = 0;
    } else {
      this.valueReal = this.valueEntry.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
      this.value = this.valuePercenty;
    }
  }

  /**
   * Função que retornar os pontos da regua
   * @param val number
   */
  marks(val: number): any {
    if (
      val == 0 ||
      val == 20 ||
      val == 10 ||
      val == 30 ||
      val == 40 ||
      val == 50 ||
      val == 99
    ) {
      return { label: `${val}%` };
    }

    return false;
  }

  changeRangeValue(): void {
    const valueCarFloat = (this.valueCar * 0.99).toFixed(2);
    const numberValue =
      typeof this.valueReal === "string"
        ? this.getNumberFromCurrency(this.valueReal)
        : this.valueReal;

    this.valueInput = true;

    if (numberValue > this.valueCar * 0.99) {
      this.value = 99;
      this.valueReal = valueCarFloat;
    } else if (numberValue < 0) {
      this.value = 0;
      this.valueReal = 0;
    } else {
      this.value = (100 * numberValue) / this.valueCar;
    }

    //Coloca valor que vem do input no padrão 0.00
    if (typeof this.valueReal == "string") {
      this.valueReal = this.valueReal.replace(".", "");
      this.valueReal = this.valueReal.replace(",", ".");
    }
  }

  changeInputValue(): void {
    this.valueInput = true;
    this.valueReal = ((this.valueCar * this.value) / 100).toFixed(2);
  }

  getNumberFromCurrency(currency: string): number {
    return Number(currency.replace(/[^0-9-]+/g, "")) / 100;
  }

  get entryValue(): any {
    if (typeof this.value == "number") {
      return parseFloat(this.value.toFixed(2));
    }
    return this.value;
  }

  @Watch("valueReal", { immediate: true })
  removeNegative(): void {
    if (typeof this.valueReal == "string") {
      this.valueReal = this.valueReal.replace("-", "");
    }
  }

  @Watch("entryValue")
  returnValue(): void {
    if (typeof this.valueReal == "string" && !this.valueInput) {
      this.valueReal = this.valueReal.replace(".", "");
      this.valueReal = this.valueReal.replace(",", ".");
    }
    // Emitir o valor
    this.$emit("updateInput", {
      real: this.valueReal,
      percent: this.entryValue,
    });
  }

  get userStyle(): {
    "--color-primary": string;
  } {
    return {
      "--color-primary": this.colorMain,
    };
  }
}
